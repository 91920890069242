async function generateHash(message: string): Promise<string> {
	const msgUint8 = new TextEncoder().encode(message);

	const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);

	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray
		.map((b) => b.toString(16).padStart(2, '0'))
		.join('');

	return hashHex;
}

interface HashInput {
	audiences: string[];
	tones: string[];
	title: string;
	description: string;
	template?: any;
	promotedObjectName?: string;
	promotedObjectDescription?: string;
}

const PREDEFINED_RATIOS = {
	'1:3': 1 / 3,
	'1:2': 1 / 2,
	'2:3': 2 / 3,
	'3': 3,
	'2': 2,
	'3:2': 3 / 2,
};

function findClosestRatio(actualRatio: number): string {
	let closestRatio = '';
	let minDifference = Number.MAX_VALUE;

	for (const [ratioName, ratioValue] of Object.entries(PREDEFINED_RATIOS)) {
		const difference = Math.abs(actualRatio - ratioValue);
		if (difference < minDifference) {
			minDifference = difference;
			closestRatio = ratioName;
		}
	}

	return closestRatio;
}

function extractAspectRatio(template: any): string {
	if (!template) return 'NO_IMAGE';

	const findProductElement = (elements: any[]): any => {
		if (!elements || !Array.isArray(elements)) return null;

		for (const element of elements) {
			if (element.name === '$Product' && element.type === 'image') {
				return element;
			}

			if (element.type === 'composition' && element.elements) {
				const found = findProductElement(element.elements);
				if (found) return found;
			}
		}

		return null;
	};

	const productElement = findProductElement(template.elements);

	if (productElement && productElement.width && productElement.height) {
		const width = parseFloat(productElement.width.replace('%', ''));
		const height = parseFloat(productElement.height.replace('%', ''));

		if (!isNaN(width) && !isNaN(height) && height !== 0) {
			const aspectRatio = width / height;
			return findClosestRatio(aspectRatio);
		}
	}

	return 'NO_IMAGE';
}

export async function generatePromotedObjectVersionHash(
	input: HashInput,
): Promise<string> {
	const {
		audiences,
		tones,
		title = '',
		description = '',
		template,
		promotedObjectName = '',
		promotedObjectDescription = '',
	} = input;

	const aspectRatio = extractAspectRatio(template);

	const dataString =
		audiences.join('') +
		tones.join('') +
		title +
		description +
		promotedObjectName +
		promotedObjectDescription +
		aspectRatio;

	return await generateHash(dataString);
}
