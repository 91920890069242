import { differenceInDays, format } from 'date-fns';
import { getSubscriptionLink } from 'src/services/billing';
import { externalRedirect } from './redirect';

export const getOrdinalSuffix = (n: number): string => {
	if (n % 100 >= 11 && n % 100 <= 13) {
		return 'th';
	}
	switch (n % 10) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};

export const formatNextPaymentDate = (date: Date): string => {
	return format(date, 'MMMM d');
};

export const getDaysAndHoursLeft = (
	targetDate: Date,
	now: Date = new Date(),
) => {
	const diffMs = targetDate.getTime() - now.getTime();
	const days = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
	const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	return { days, hours };
};

export const formatPrice = (amount: number): string => {
	const integerPart = Math.floor(amount / 100);
	const decimalPart = amount % 100;
	if (decimalPart === 0) {
		return `${integerPart}`;
	}
	return `${integerPart},${decimalPart.toString().padStart(2, '0')}`;
};

/**
 * Calculates the total trial duration in days.
 *
 * @param startDate - The trial start date.
 * @param endDate - The trial end date.
 * @returns The total number of days of the trial (rounded up).
 */
export const getTrialDuration = (startDate: Date, endDate: Date): number =>
	Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

/**
 * Returns a formatted string representing the remaining time until the target date.
 *
 * @param targetDate - The target date.
 * @param now - The current date (default is new Date()).
 * @returns A string indicating the remaining time in hours or days.
 */
export const getRemainingTime = (
	targetDate: Date,
	now: Date = new Date(),
): string => {
	const diffMs = targetDate.getTime() - now.getTime();
	// If less than one hour remains, return 'less than one hour'.
	if (diffMs < 1000 * 60 * 60) {
		return 'less than one hour';
	}
	// If less than 24 hours remain, display the exact number of hours.
	if (diffMs < 1000 * 60 * 60 * 24) {
		const hours = Math.floor(diffMs / (1000 * 60 * 60));
		return `${hours} hour${hours !== 1 ? 's' : ''}`;
	}
	// For 24 hours or more, display the remaining time in days (rounded up).
	const days = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
	return `${days} day${days > 1 ? 's' : ''}`;
};

/**
 * Calculates the trial progress percentage based on milliseconds.
 *
 * @param startDate - The trial start date.
 * @param endDate - The trial end date.
 * @param now - The current date (default is new Date()).
 * @returns The progress percentage (0 to 100).
 */
export const calculateMsBasedProgress = (
	startDate: Date,
	endDate: Date,
	now: Date = new Date(),
): number => {
	const totalMs = endDate.getTime() - startDate.getTime();
	const elapsedMs = now.getTime() - startDate.getTime();
	// Ensure the progress does not exceed 100%
	return totalMs > 0 ? Math.min((elapsedMs / totalMs) * 100, 100) : 0;
};

/**
 * Calculates the trial progress percentage based on days.
 *
 * @param startDate - The trial start date.
 * @param endDate - The trial end date.
 * @param now - The current date (default is new Date()).
 * @returns The progress percentage (0 to 100).
 */
export const calculateDayBasedProgress = (
	trialStartDate: Date,
	trialEndDate: Date,
	now: Date = new Date(),
): number => {
	const totalDays = differenceInDays(trialEndDate, trialStartDate);
	const elapsedDays = Math.max(differenceInDays(now, trialStartDate), 1);
	return totalDays > 0 ? (elapsedDays / totalDays) * 100 : 0;
};

export const goToPaymentCheckout = async (mode: 'setup' | 'subscription') => {
	try {
		const { checkoutUrl } = await getSubscriptionLink(mode);
		externalRedirect(checkoutUrl);
	} catch (error) {
		console.error(error);
	}
};
