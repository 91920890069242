import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
	Button,
	Flex,
	Heading,
	Image,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tag,
	Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import DataTable from 'src/components/common/DataTable/DataTable';
import FusionLoading from 'src/components/common/FusionLoading';
import { InvalidAccountBanner } from 'src/components/dashboard';
import UserContext from 'src/contexts/user/UserContext';
import usePagination from 'src/hooks/usePagination';
import CustomContainer from 'src/components/common/CustomContainer';
import { customToast, toastError, toastSuccess } from 'src/services/toast';
import { ColumnDef } from '@tanstack/react-table';
import {
	getTemplateById,
	getTemplatesByIndustry,
	makeTemplatePublic,
} from 'src/services/templates';
import TemplateContext, {
	ITemplate,
} from 'src/contexts/templates/TemplatesContext';
import DataTablePagination from 'src/components/common/DataTable/DataTablePagination';

const Templates: React.FC = () => {
	const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
	const { user, isLoading: isUserLoading } = useContext(UserContext);
	const { selectedTemplate } = useContext(TemplateContext);

	const pagination = usePagination();
	const navigate = useNavigate();
	const [refreshTemplates, setRefreshTemplates] = useState(false);
	const placeholder = 'https://via.placeholder.com/150';
	const [accountTemplates, setAccountTemplates] = useState<ITemplate[]>([]);
	const [platformTemplates, setPlatformTemplates] = useState<ITemplate[]>([]);

	const initialTab = selectedTemplate?.scope === 'platform' ? 1 : 0;
	const [selectedTab, setSelectedTab] = useState(initialTab);

	const handleTabChange = (index: number) => {
		setSelectedTab(index);
	};

	const { setSeasonValues, setStyleValues } = useContext(TemplateContext);

	const fetchTemplatesByScope = async () => {
		try {
			const queryParams = {
				isInternal: true,
				extraParams: 'no_cache=true',
			};
			const { results: allTemplates, facets } = await getTemplatesByIndustry(
				undefined,
				queryParams,
			);

			const seasonsFacet = facets.find((f) => f.id === 'seasons');
			if (seasonsFacet) {
				setSeasonValues(seasonsFacet.values.map((item: any) => item.value));
			}
			const stylesFacet = facets.find((f) => f.id === 'styles');
			if (stylesFacet) {
				setStyleValues(stylesFacet.values.map((item: any) => item.value));
			}

			const filteredAccountTemplates = allTemplates.filter(
				(template: ITemplate) => template.scope === 'account',
			);
			const filteredPlatformTemplates = allTemplates.filter(
				(template: ITemplate) => template.scope === 'platform',
			);

			setAccountTemplates(filteredAccountTemplates);
			setPlatformTemplates(filteredPlatformTemplates);
		} catch (error: any) {
			console.error('Failed to fetch templates:', error);
			toastError('Error loading templates');
		} finally {
			setIsLoadingTemplates(false);
		}
	};

	useEffect(() => {
		if (!isUserLoading && user?.account) {
			setIsLoadingTemplates(true);
			fetchTemplatesByScope();
		}
	}, [user, isUserLoading, pagination.page, refreshTemplates]);

	const loadingReducer = (state: any, action: any) => {
		switch (action.type) {
			case 'SET_LOADING':
				return { ...state, [action.id]: true };
			case 'CLEAR_LOADING':
				return { ...state, [action.id]: false };
			default:
				return state;
		}
	};

	const [loadingStates] = useReducer(loadingReducer, {});

	const handleEditTemplate = async (
		id: string,
		scope: 'platform' | 'account',
	) => {
		setIsLoadingTemplates(true);
		try {
			const detailedTemplate = await getTemplateById(user!.account, id, scope);

			detailedTemplate.scope = scope;

			navigate(`/account/templates/edit/${id}`, {
				state: { template: detailedTemplate },
			});
		} catch (error) {
			toastError(`Failed to load template: ${error}`);
		} finally {
			setIsLoadingTemplates(false);
		}
	};

	const handleMakePublic = async (
		template: ITemplate,
		setLoading: (state: boolean) => void,
	) => {
		if (!template.variations || template.variations.length === 0) {
			customToast('Variations are missing', 'error');
			setLoading(false);
			return;
		}

		if (!template.attributes || !template.attributes.thumbnail) {
			customToast('Thumbnail is missing', 'error');
			setLoading(false);
			return;
		}

		try {
			await makeTemplatePublic(template.id);
			setLoading(false);
			setRefreshTemplates((prev) => !prev);
			toastSuccess('Template made public successfully!');
		} catch (error: any) {
			toastError(error);
			setLoading(false);
		}
	};

	const handleCreateTemplate = () => {
		navigate('/account/templates/new');
	};

	const SpinnerButton = React.memo(({ template }: { template: ITemplate }) => {
		const [isLoading, setIsLoading] = useState(false);
		const handleClick = () => {
			setIsLoading(true);
			handleMakePublic(template, setIsLoading);
		};

		return (
			<Button
				size="sm"
				isLoading={isLoading}
				onClick={handleClick}
				colorScheme="secondary"
			>
				Make it public
			</Button>
		);
	});

	SpinnerButton.displayName = 'SpinnerButton';

	const createColumns = React.useCallback(
		(isPublic: boolean): ColumnDef<any, any>[] => [
			{
				accessorKey: 'thumbnail',
				header: 'Thumbnail',
				cell: ({ row }) => (
					<Image
						src={row.original.attributes?.thumbnail ?? placeholder}
						alt={`${row.original.id} Thumbnail`}
						boxSize="50px"
						borderRadius="md"
						objectFit="cover"
					/>
				),
			},
			{
				accessorKey: 'id',
				header: 'Name',
				cell: ({ row }) => <Text>{row.original.id}</Text>,
			},
			{
				accessorKey: 'enabled',
				header: 'Enabled',
				cell: ({ row }) => (
					<Tag
						colorScheme={row.original.enabled ? 'green' : 'gray'}
						variant={row.original.enabled ? 'solid' : 'outline'}
						borderRadius="full"
					>
						{row.original.enabled ? 'Enabled' : 'Disabled'}
					</Tag>
				),
			},
			{
				id: 'actions',
				header: 'Actions',
				cell: ({ row }) => (
					<Flex direction="row" gap={5}>
						<Button
							size="sm"
							onClick={() => {
								const scope = isPublic ? 'platform' : 'account';

								handleEditTemplate(row.original.id, scope);
							}}
							colorScheme="secondary"
						>
							Edit
						</Button>
						{!isPublic && <SpinnerButton template={row.original} />}
					</Flex>
				),
			},
		],
		[loadingStates, handleEditTemplate],
	);

	const showInvalidAccountBanner = !isUserLoading && !user?.account;
	const showTemplates = !isUserLoading && user?.account;

	const transformedTemplates = accountTemplates.map((template) => ({
		...template,
		id: template.id.replace(/^account-/, ''),
	}));

	return (
		<CustomContainer>
			<FusionLoading isLoading={isUserLoading && !user?.account} />
			{showInvalidAccountBanner && <InvalidAccountBanner />}
			{showTemplates && (
				<Flex direction="column" gap={6} pb={24}>
					<Flex alignItems="center" justifyContent="space-between">
						<Heading>Templates</Heading>
						<Button
							size="sm"
							onClick={handleCreateTemplate}
							colorScheme="secondary"
						>
							Create New Template
						</Button>
					</Flex>
					<Tabs index={selectedTab} onChange={handleTabChange}>
						<TabList>
							<Tab>Your Templates</Tab>
							<Tab>Public Templates</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<DataTable
									columns={createColumns(false)}
									data={transformedTemplates}
									isLoading={isLoadingTemplates}
									tableProps={{
										border: '1px solid #F5F5F5',
										minH: '400px',
									}}
									trProps={{
										borderBottomWidth: '1px',
										borderColor: '#F5F5F5',
									}}
								/>
								<DataTablePagination {...pagination} />
							</TabPanel>
							<TabPanel>
								<DataTable
									columns={createColumns(true)}
									data={platformTemplates}
									isLoading={isLoadingTemplates}
									tableProps={{
										border: '1px solid #F5F5F5',
										minH: '400px',
									}}
									trProps={{
										borderBottomWidth: '1px',
										borderColor: '#F5F5F5',
									}}
								/>
								<DataTablePagination {...pagination} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Flex>
			)}
		</CustomContainer>
	);
};

export default Templates;
