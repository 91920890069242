import { FC, useContext, useEffect, useState } from 'react';
import { Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { StringInputHook } from 'src/components/common/form';
import UserContext from 'src/contexts/user/UserContext';
import { SingUpSchema, ISingUpForm } from 'src/lib/schemas';
import AlertMessage from 'src/components/common/AlertMessage';
import authService from 'src/services/auth';
import { completeRegistration } from 'src/services/scraping';
import FusionLoading from 'src/components/common/FusionLoading';

interface CustomWindow extends Window {
	dataLayer: Array<Record<string, any>>;
}
declare const window: CustomWindow;

interface ISignUpForm {
	urlWithoutPrefix?: string;
	withHeader?: boolean;
	pendingCampaginGeneration?: boolean;
	brandData: any;
}

const SignUpForm: FC<ISignUpForm> = ({
	withHeader = true,
	urlWithoutPrefix,
	pendingCampaginGeneration,
	brandData,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const formMethods = useForm<ISingUpForm>({
		resolver: zodResolver(SingUpSchema),
	});
	const { setUser } = useContext(UserContext);
	const navigate = useNavigate();

	const watchedEmail = formMethods.watch('email');

	useEffect(() => {
		if (watchedEmail && watchedEmail !== watchedEmail.trim()) {
			formMethods.setValue('email', watchedEmail.trim());
		}
	}, [watchedEmail]);

	useEffect(() => {
		if (isSubmitting && pendingCampaginGeneration) {
			setShowLoading(true);
		} else if (!pendingCampaginGeneration && isSubmitting) {
			handleCompleteRegistration();
		}
	}, [pendingCampaginGeneration, isSubmitting]);

	const handleCompleteRegistration = async () => {
		const id = authService.getCurrentUser()?.id;
		if (!id) return;

		setError(null);

		try {
			const payload = {
				...formMethods.getValues(),
				brandData,
			};
			const user = await completeRegistration(payload, id);

			if (user && !user.isEmailVerified) {
				window.dataLayer.push({
					event: 'successful_sign_up',
					user_id: user.id,
					email: user.email,
					name: user.name,
					account_id: user.account,
				});
				authService.cleanupSession();
				setUser(null);
				navigate('/pending-verification', { state: { fromSignup: true } });
			}
		} catch (error: any) {
			const { message } = error.response?.data || 'An error occurred';
			setError(message);
		} finally {
			setIsSubmitting(false);
			setShowLoading(false);
			formMethods.reset({ password: '' });
		}
	};

	const onSubmit = formMethods.handleSubmit(() => {
		setIsSubmitting(true);
	});

	if (showLoading) return <FusionLoading isLoading />;

	return (
		<Flex flex={1} direction="column" gap={5} margin="auto">
			{withHeader && (
				<Heading size="md" textAlign="center">
					Complete the sign up to see your ad
				</Heading>
			)}
			<FormProvider {...formMethods}>
				{error && <AlertMessage status="error">{error}</AlertMessage>}
				<form onSubmit={onSubmit}>
					<Flex flexDirection="column" justifyContent="center" gap="20px">
						<StringInputHook
							label="Name"
							name="name"
							placeholder="Your full name"
							required
							inputProps={{ size: 'md', fontSize: '14px' }}
						/>
						<StringInputHook
							label="Email"
							name="email"
							placeholder={`you@${urlWithoutPrefix ?? 'example.com'}`}
							required
							inputProps={{ size: 'md', fontSize: '14px' }}
						/>
						<Flex direction="column" gap="3">
							<StringInputHook
								label="Password"
								name="password"
								type={isPasswordVisible ? 'text' : 'password'}
								placeholder="Strong password"
								required
								inputProps={{ size: 'md', fontSize: '14px' }}
							/>
							<Flex alignItems="center">
								<input
									type="checkbox"
									name="showPassword"
									id="showPassword"
									onChange={() => setIsPasswordVisible(!isPasswordVisible)}
								/>
								<label htmlFor="showPassword">
									<Text fontSize="13px">&nbsp; Show password</Text>
								</label>
							</Flex>
						</Flex>
						<Flex
							flexDirection={{ base: 'column', md: 'row' }}
							justifyContent="space-between"
							alignItems="center"
							w="full"
							gap="10px"
						>
							<Text
								fontSize={{ base: '12px', md: '14px' }}
								textAlign={{ base: 'center', md: 'left' }}
							>
								By clicking “Sign Up” you agree to our{' '}
								<Link textDecor="underline">Terms of Service</Link> and{' '}
								<Link textDecor="underline">Privacy Notice</Link>
							</Text>
							<Button
								variant="orangeSolid"
								minW="120px"
								type="submit"
								loadingText="Loading..."
							>
								Sign Up
							</Button>
						</Flex>
					</Flex>
				</form>
			</FormProvider>
		</Flex>
	);
};

export default SignUpForm;
