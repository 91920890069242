import { useContext, useEffect, useState } from 'react';
import RedirectPage from 'src/components/common/RedirectPage';
import UserContext from 'src/contexts/user/UserContext';
import { toastSuccess } from 'src/services/toast';
import { BillingStatus } from 'src/models/account/interfaces';
import FusionLoading from 'src/components/common/FusionLoading';
import { Box, Text } from '@chakra-ui/react';

const SuccessPage = () => {
	const { subscription, fetchSubscription } = useContext(UserContext);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (!subscription) return;

		let intervalId: NodeJS.Timeout;
		let timeoutId: NodeJS.Timeout;
		const startTime = Date.now();

		const pollSubscription = async () => {
			if (
				subscription.billingStatus === BillingStatus.Inactive ||
				subscription.billingStatus === BillingStatus.TrialExpired
			) {
				await fetchSubscription();
			}
			if (
				subscription.billingStatus !== BillingStatus.Inactive &&
				subscription.billingStatus !== BillingStatus.TrialExpired
			) {
				setReady(true);
				clearInterval(intervalId);
				clearTimeout(timeoutId);
			} else if (Date.now() - startTime > 5000) {
				setReady(true);
				clearInterval(intervalId);
			}
		};

		pollSubscription();
		intervalId = setInterval(pollSubscription, 500);
		timeoutId = setTimeout(() => {
			clearInterval(intervalId);
			setReady(true);
		}, 5000);

		return () => {
			clearInterval(intervalId);
			clearTimeout(timeoutId);
		};
	}, [subscription, fetchSubscription]);

	return ready ? (
		<RedirectPage
			callback={() => toastSuccess('Payment method added successfully')}
		/>
	) : (
		<Box display="flex" justifyContent="center">
			<Box textAlign="center">
				<FusionLoading isLoading={true} />
				<Text size="md" mt={4}>
					We are updating your subscription
				</Text>
			</Box>
		</Box>
	);
};

export default SuccessPage;
