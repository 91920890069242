import { useContext } from 'react';
import { Progress } from '@chakra-ui/react';
import {
	calculateMsBasedProgress,
	calculateDayBasedProgress,
} from 'src/lib/utils/billing';
import UserContext from 'src/contexts/user/UserContext';

const TrialProgressBar = () => {
	const { subscription } = useContext(UserContext);
	if (!subscription) return null;

	const { startDate, freeTrialEnd } = subscription;
	const trialStartDate = new Date(startDate);
	const trialEndDate = new Date(freeTrialEnd);
	const milisecondBasedProgress = calculateMsBasedProgress(
		trialStartDate,
		trialEndDate,
	);
	const dayBasedProgress = calculateDayBasedProgress(
		trialStartDate,
		trialEndDate,
	);

	const progressPercent = Math.max(milisecondBasedProgress, dayBasedProgress);
	return (
		<Progress value={progressPercent} size="sm" borderRadius="md" mb={4} />
	);
};

export default TrialProgressBar;
