import React, {
	FC,
	useContext,
	useMemo,
	useState,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import {
	Box,
	Button,
	Text,
	useColorModeValue,
	HStack,
	Flex,
	CloseButton,
	Collapse,
	Icon,
	Skeleton,
	Grid,
	keyframes,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
} from '@chakra-ui/react';
import { FiChevronDown, FiX } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	SearchIcon,
} from '@chakra-ui/icons';
import TemplateContext, {
	ITemplate,
} from 'src/contexts/templates/TemplatesContext';
import { CampaignContext } from 'src/contexts';
import CustomModal from 'src/components/common/CustomModal';
import FilterDropdown from 'src/components/common/FilterDropdown';
import FusionLoading from 'src/components/common/FusionLoading';
import {
	generateDesignDirectionsFromLayers,
	generateDesignDirectionsWithTemplates,
} from 'src/services/campaign';
import { toastError } from 'src/services/toast';
import { useNavigate } from 'react-router-dom';
import { processTemplateData } from 'src/services/templates-sdk';
import TemplatePreview, {
	TemplatePreviewHandle,
} from 'src/components/templates/TemplatePreview';
import { getTemplateLayers } from 'src/services/templates';
import { processCallback } from 'src/lib/utils/processCallback';
import { transformLayers } from '../utils/layers';
import {
	CreatomateElement,
	ICreatomateTemplate,
} from 'src/models/creatomate-template/interfaces';
import { ElementType } from 'src/models/creatomate-template/enums';
import { shouldShowTemplateIds } from 'src/utils/enviroment';

function isCreatomateTemplate(
	template: ITemplate | ICreatomateTemplate,
): template is ICreatomateTemplate {
	return 'templateId' in template;
}

interface FilteredTemplates {
	myTemplates: ITemplate[];
	allTemplates: ITemplate[];
	totalMyTemplates: number;
	totalAllTemplates: number;
}

interface SelectTemplateModalProps {
	isOpen: boolean;
	onClose: () => void;
	header?: string;
	handleRefreshDesignDirections?: () => Promise<void>;
	size?: 'full' | any;
	isTemplateModal?: boolean;
	setIsGeneratingDD?: React.Dispatch<React.SetStateAction<boolean>>;
	selectedTemplateIds?: string[];
	source?:
		| 'campaignForm'
		| 'designDirections'
		| 'changeTemplates'
		| 'gallery'
		| 'sidebar';
	onSubmit?: (layeredTemplates?: any[]) => void;
	promotedObjectName?: string;
	promotedObjectDescription?: string;
}

const MotionBox = motion(Box);

const SelectTemplateModal: FC<SelectTemplateModalProps> = ({
	isOpen,
	onClose,
	header = 'Template Gallery',
	size = 'full',
	handleRefreshDesignDirections,
	source,
	onSubmit,
	setIsGeneratingDD,
	promotedObjectName,
	promotedObjectDescription,
}) => {
	const TEMPLATES_PER_PAGE = 24;
	const TEMPLATES_PER_BATCH = 8;
	const CALLBACK_MAX_ATTEMPTS = 100;

	const showCustomThumbnails =
		source === 'designDirections' || source === 'campaignForm';

	const {
		templatesByScope,
		templates,
		selectedTemplateIds,
		fetchIndustries,
		templatesLoaded,
		setSelectedTemplateIds,
		industries,
		industriesLoaded,
		fetchTemplatesByScope,
		styles,
		seasons,
		creatomatePlatformTemplates,
		creatomateAccountTemplates,
		customTemplates,
		setCustomTemplates,
		customAccountTemplates,
		setCustomAccountTemplates,
		processedCustomTemplates,
		setProcessedCustomTemplates,
		layeredTemplates,
		setLayeredTemplates,
		setFilteredCustomTemplates,
		filteredCustomTemplates,
		lastSearchWithCampaign,
	} = useContext(TemplateContext);
	const navigate = useNavigate();
	const {
		id: campaignId,
		campaign,
		wasCampaignChanged,
		setWasCampaignChanged,
	} = useContext(CampaignContext);
	const [scope, setScope] = useState<string>('all');
	const [columns, setColumns] = useState<number>(5);
	const [isExpanded, setIsExpanded] = useState<boolean>(true);
	const [topTemplatesSelected, setTopTemplatesSelected] =
		useState<boolean>(false);
	const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
	const [selectedStyles, setSelectedStyles] = useState<string[]>([]);
	const [selectedSeasons, setSelectedSeasons] = useState<string[]>([]);
	const [hasAutoSelectedTopTemplates, setHasAutoSelectedTopTemplates] =
		useState<boolean>(false);
	const [isBeginning, setIsBeginning] = useState<boolean>(true);
	const [isEnd, setIsEnd] = useState<boolean>(false);
	const [hoveredTemplateId, setHoveredTemplateId] = useState<string | null>(
		null,
	);
	const [searchInput, setSearchInput] = useState<string>('');

	const [currentSelectedTemplatesIds, setCurrentSelectedTemplatesIds] =
		useState<string[]>(selectedTemplateIds || []);
	const [extendedHoveredTemplateId, setExtendedHoveredTemplateId] = useState<
		string | null
	>(null);
	const [hasSelectedFilters, setHasSelectedFilters] = useState<boolean>(false);
	const [showMyTemplates, setShowMyTemplates] = useState<boolean>(false);
	//Pending templates is now deprecated (Delete it)
	const [pendingTemplates, setPendingTemplates] = useState<string[]>([]);
	const [isLoadingMoreTemplates, setIsLoadingMoreTemplates] = useState(false);
	const [page, setPage] = useState(0);
	const loadedPages = useRef(new Set<number>());
	const bottomRef = useRef<HTMLDivElement | null>(null);
	const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const swiperRef = useRef<any>(null);
	const debounceRef = useRef(false);
	const [modalLoading, setModalLoading] = useState<boolean>(false);
	const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
	const [displayLimit, setDisplayLimit] = useState<number>(TEMPLATES_PER_PAGE);
	const [filteredDisplayLimit, setFilteredDisplayLimit] =
		useState<number>(TEMPLATES_PER_PAGE);
	const modalRef = useRef<HTMLDivElement>(null);
	const previewRefs = useRef<{ [key: string]: TemplatePreviewHandle | null }>(
		{},
	);
	const isLoading = !templatesLoaded || !industriesLoaded;
	const [searchTemplateId, setSearchTemplateId] = useState<string>('');
	const hasSearchedRef = useRef<boolean>(false);
	const processingBatch = useRef(false);
	const cancelBatchProcessing = useRef(false);

	useEffect(() => {
		if (isOpen) {
			cancelBatchProcessing.current = false;
		}
	}, [isOpen]);

	const getTemplateForPreview = (template: any) => {
		const isCreatomate = isCreatomateTemplate(template);

		if (isCreatomate) {
			const updatedTemplate = [
				...customTemplates,
				...customAccountTemplates,
			].find((t) => t?.templateId === template?.templateId);
			if (updatedTemplate) {
				template = updatedTemplate;
			}
		}

		if (
			source &&
			['gallery', 'sidebar', 'changeTemplates'].includes(source) &&
			(template as ITemplate)?.attributes?.thumbnail
		) {
			return template.attributes.thumbnail;
		}
		return (
			template?.variations?.find((v: any) => v?.id === 'design')?.template ||
			template
		);
	};

	const shouldFetchCustomTemplates =
		wasCampaignChanged ||
		(showCustomThumbnails && !processedCustomTemplates.length && page === 0);

	const shouldShowTopTemplates = useMemo(() => {
		const hasPendingOrGeneratingDirections =
			campaign?.designDirections?.some(
				(direction) =>
					direction.status === 'pending' || direction.status === 'generating',
			) ?? false;
		return (
			selectedTemplateIds.length === 0 &&
			source === 'campaignForm' &&
			campaign?.designDirections?.length === 0 &&
			!hasPendingOrGeneratingDirections
		);
	}, [selectedTemplateIds, source, campaign?.designDirections]);

	const resetCampaignChangeStatus = () => setWasCampaignChanged(false);

	const invertAnimation = keyframes`
  0%, 80% { backdrop-filter: invert(0%); }
  50% { backdrop-filter: invert(100%); }
`;

	const hasActiveFilter =
		(searchTemplateId && searchTemplateId.trim() !== '') ||
		selectedIndustries.length > 0 ||
		selectedStyles.length > 0 ||
		selectedSeasons.length > 0;

	useEffect(() => {
		if (!searchInput && !hasActiveFilter) {
			setSearchTemplateId('');
			setFilteredCustomTemplates([]);
		}
	}, [searchInput, hasActiveFilter]);

	const handleSearch = () => {
		const searchTemplateId = searchInput.trim();
		setSearchTemplateId(searchTemplateId);
	};

	useEffect(() => {
		const customTemplateIds = new Set(
			customTemplates.map((template) => template?.templateId),
		);
		const processedTemplateIds = new Set(
			processedCustomTemplates.map((template) => template?.templateId),
		);

		const templatesToFetch = filteredCustomTemplates.filter(
			(template) =>
				!customTemplateIds.has(template?.templateId) &&
				!processedTemplateIds.has(template?.templateId),
		);

		fetchFilteredCustomTemplates(templatesToFetch);
	}, [
		filteredCustomTemplates.map((template) => template?.templateId).join(','),
	]);

	const isFetchingFilteredTemplatesRef = useRef(false);

	const fetchFilteredCustomTemplates = async (
		templateList: ICreatomateTemplate[],
	) => {
		if (!campaignId) return;
		if (isFetchingFilteredTemplatesRef.current) return;
		isFetchingFilteredTemplatesRef.current = true;
		try {
			let currentIndex = 0;
			while (currentIndex < templateList.length) {
				const batch = templateList.slice(
					currentIndex,
					currentIndex + TEMPLATES_PER_BATCH,
				);
				await Promise.allSettled(
					batch.map(async (template) => {
						try {
							const { callback } = await getTemplateLayers(
								template?.templateId,
								campaignId,
								campaign,
								template,
								promotedObjectName,
								promotedObjectDescription,
							);
							const creatomateAd = await processCallback(
								callback,
								CALLBACK_MAX_ATTEMPTS,
							);

							if (
								creatomateAd?.status === 'failed' ||
								creatomateAd.status === 'successful'
							) {
								processCreatomateContent(creatomateAd);
							}

							if (creatomateAd && creatomateAd.status === 'partialContent') {
								await pollProcessCallback(callback, processCreatomateContent);
							}
						} catch (error) {
							console.error(
								`Error processing template ${template?.templateId}:`,
								error,
							);
							setProcessedCustomTemplates((prev) => {
								if (!prev.find((t) => t.templateId === template?.templateId)) {
									return [...prev, template];
								}
								return prev;
							});
						}
					}),
				);
				currentIndex += TEMPLATES_PER_BATCH;
			}
		} finally {
			isFetchingFilteredTemplatesRef.current = false;
		}
	};

	useEffect(() => {
		if (campaignId) {
			setPage(0);
			setCustomTemplates([]);
			setCustomAccountTemplates([]);
			setProcessedCustomTemplates([]);
			loadedPages.current.clear();
			if (creatomateAccountTemplates.length > 0) {
				fetchCustomAccountTemplates();
			}
			if (creatomatePlatformTemplates.length > 0) {
				fetchCustomTemplates(0);
			}
			resetCampaignChangeStatus();
		}
	}, [campaignId]);

	//TODO: Update this function (see fetchCustomTemplates)
	const fetchCustomAccountTemplates = async () => {
		if (
			!campaignId ||
			!creatomateAccountTemplates.length ||
			(customAccountTemplates.length > 0 && !wasCampaignChanged)
		) {
			return;
		}

		try {
			const timeout = new Promise((_, reject) =>
				setTimeout(() => reject(new Error('Request timed out')), 60000),
			);
			await Promise.race([
				Promise.all(
					creatomateAccountTemplates.map(async (template) => {
						try {
							const { callback } = await getTemplateLayers(
								template?.templateId,
								campaignId,
							);
							const layeredTemplate = await processCallback(callback);
							const updatedLayers = transformLayers(template, layeredTemplate);
							const result = await processTemplateData(
								template,
								updatedLayers,
								false,
							);
							setProcessedCustomTemplates((prevState) => [
								...prevState,
								template,
							]);
							setLayeredTemplates((prevState) => {
								return prevState.some(
									(item) => item.template?.id === layeredTemplate?.id,
								)
									? prevState.map((item) =>
											item.template?.id === layeredTemplate?.id
												? layeredTemplate
												: item,
									  )
									: [...prevState, layeredTemplate];
							});
							setCustomAccountTemplates((prevState) => {
								const existingIds = new Set(
									prevState.map((temp) => temp?.templateId),
								);
								if (!existingIds.has(result?.id)) {
									return [...prevState, result];
								}
								return prevState.map((temp) =>
									temp?.templateId === result?.id ? result : temp,
								);
							});
						} catch {
							setCustomAccountTemplates((prevState) => {
								const existingIds = new Set(
									prevState.map((temp) => temp?.templateId),
								);
								if (!existingIds.has(template?.templateId)) {
									return [...prevState, template];
								}
								return prevState;
							});
						} finally {
							setPendingTemplates((prev) =>
								prev.filter((id) => id !== template?.templateId),
							);
						}
					}),
				),
				timeout,
			]);
		} catch {
		} finally {
			setPendingTemplates((prev) =>
				prev.filter(
					(id) =>
						!creatomateAccountTemplates.some(
							(template) => template?.templateId === id,
						),
				),
			);
		}
	};

	useEffect(() => {
		if (
			creatomatePlatformTemplates.length > 0 &&
			customTemplates.length === 0
		) {
			const initialTemplates = (
				processedCustomTemplates.length
					? processedCustomTemplates
					: creatomatePlatformTemplates
			)
				.filter(
					(template) =>
						template !== undefined &&
						!Array.isArray(template) &&
						template.elements !== undefined,
				)
				.slice(0, TEMPLATES_PER_PAGE);
			setCustomTemplates(initialTemplates);
		}
	}, [creatomatePlatformTemplates, customTemplates]);

	const pollProcessCallback = async (
		callbackUrl: string,
		callbackFunc: Function,
		delay = 1000,
		maxAttempts = 60,
		attempt = 1,
	): Promise<ICreatomateTemplate | null> => {
		const res = await processCallback(callbackUrl);

		if (attempt >= maxAttempts) {
			res.status = 'failed';
		}

		callbackFunc(res);

		if (res.status === 'successful' || res.status === 'failed') {
			return res;
		}

		await new Promise((resolve) => setTimeout(resolve, delay));
		return await pollProcessCallback(
			callbackUrl,
			callbackFunc,
			delay,
			maxAttempts,
			attempt + 1,
		);
	};

	useEffect(() => {
		setIsLoadingMoreTemplates(false);

		setCurrentSelectedTemplatesIds((prev) => {
			const allTemplates = [
				...creatomatePlatformTemplates,
				...creatomateAccountTemplates,
			];
			return prev.filter((id) =>
				allTemplates.some((t) => t?.templateId === id),
			);
		});
	}, [creatomatePlatformTemplates.length, creatomateAccountTemplates.length]);

	const maskDefaultContent = async (
		elements: CreatomateElement[],
	): Promise<void> => {
		if (elements) {
			elements.forEach((element) => {
				if (element.elements) {
					maskDefaultContent(element.elements);
				}
				if (
					element.type === ElementType.TEXT &&
					element.name?.startsWith('$')
				) {
					if (!element.__isMasked) {
						element.__original = {
							fill_color: element.fill_color,
							shadow_color: element.shadow_color,
							shadow_blur: element.shadow_blur,
							stroke_color: element.stroke_color,
							stroke_width: element.stroke_width,
						};
						element.__isMasked = true;
					}
					element.fill_color = 'rgba(255,255,255,0.01)';
					element.shadow_color = 'white';
					element.shadow_blur = '32px';
					element.stroke_color = 'rgba(255,255,255,0.05)';
					element.stroke_width = '2 vmin';
				}
			});
		}
	};

	const restoreOriginalContent = (elements: CreatomateElement[]): void => {
		if (elements) {
			elements.forEach((element) => {
				if (element.__isMasked && element.__original) {
					element.fill_color = element.__original.fill_color;
					element.shadow_color = element.__original.shadow_color;
					element.shadow_blur = element.__original.shadow_blur;
					element.stroke_color = element.__original.stroke_color;
					element.stroke_width = element.__original.stroke_width;
					delete element.__isMasked;
					delete element.__original;
				}
				if (element.elements) {
					restoreOriginalContent(element.elements);
				}
			});
		}
	};

	//Todo: Add support for Account templates
	const processCreatomateContent = async (
		finalLayeredTemplate: ICreatomateTemplate | null,
	): Promise<void> => {
		if (finalLayeredTemplate) {
			if (
				finalLayeredTemplate.status === 'successful' ||
				finalLayeredTemplate.status === 'timeout' ||
				finalLayeredTemplate.status === 'error' ||
				finalLayeredTemplate.status === 'failed'
			) {
				setProcessedCustomTemplates((prevState) => {
					const templateIdx = prevState.findIndex(
						(t) => t.templateId === finalLayeredTemplate.templateId,
					);

					if (templateIdx == -1) {
						prevState = [...prevState, finalLayeredTemplate];
					} else {
						prevState[templateIdx] = finalLayeredTemplate;
					}

					return prevState;
				});
			}

			if (finalLayeredTemplate.status !== 'timeout') {
				setCustomTemplates((prevState) => {
					const templateIdx = prevState.findIndex(
						(t) => t.templateId === finalLayeredTemplate.templateId,
					);

					if (templateIdx == -1) {
						prevState = [...prevState, finalLayeredTemplate];
					} else {
						prevState[templateIdx] = finalLayeredTemplate;
					}

					return prevState;
				});
			}
		}
	};

	const processBatch = async (batch: ICreatomateTemplate[]) => {
		if (cancelBatchProcessing.current) {
			return;
		}
		let activeTasks = 0;
		let currentIndex = 0;

		await new Promise<void>((resolve) => {
			function runNext() {
				if (cancelBatchProcessing.current) {
					resolve();
					return;
				}
				if (
					cancelBatchProcessing.current ||
					(currentIndex >= batch.length && activeTasks === 0)
				) {
					resolve();
					return;
				}
				while (
					activeTasks < TEMPLATES_PER_BATCH &&
					currentIndex < batch.length
				) {
					const template = batch[currentIndex++];
					activeTasks++;

					(async () => {
						try {
							const { callback } = await getTemplateLayers(
								template?.templateId,
								campaignId!,
								campaign,
								template,
								promotedObjectName,
								promotedObjectDescription,
							);
							const creatomateAd = await processCallback(
								callback,
								CALLBACK_MAX_ATTEMPTS,
							);

							if (
								creatomateAd?.status === 'timeout' ||
								creatomateAd?.status === 'failed' ||
								creatomateAd?.status === 'error'
							) {
								setProcessedCustomTemplates((prevState) => {
									const defaultTemplateContent =
										creatomatePlatformTemplates.find(
											(t) => t.templateId === template?.templateId,
										);

									if (defaultTemplateContent) {
										const templateIdx = prevState.findIndex(
											(t) => t.templateId === template?.templateId,
										);

										if (templateIdx === -1) {
											prevState = [...prevState, defaultTemplateContent];
										}
									}

									return prevState;
								});
							}

							if (creatomateAd) {
								if (creatomateAd.status === 'successful') {
									await processCreatomateContent(creatomateAd);
								}
								if (creatomateAd.status === 'partialContent') {
									await pollProcessCallback(callback, processCreatomateContent);
								}
							}
						} catch (error) {
							setProcessedCustomTemplates((prevState) => {
								const defaultTemplateContent = creatomatePlatformTemplates.find(
									(t) => t.templateId === template?.templateId,
								);

								if (defaultTemplateContent) {
									const templateIdx = prevState.findIndex(
										(t) => t.templateId === template?.templateId,
									);

									if (templateIdx === -1) {
										prevState = [...prevState, defaultTemplateContent];
									}
								}

								return prevState;
							});
							console.error(
								`Error processing template ${template?.templateId}:`,
								error,
							);
						} finally {
							activeTasks--;
							runNext();
						}
					})();
				}
			}
			runNext();
		});
	};

	const processNewBatch = async (batch: ICreatomateTemplate[]) => {
		while (processingBatch.current) {
			await new Promise((resolve) => setTimeout(resolve, 100));
		}
		processingBatch.current = true;
		await processBatch(batch);
		processingBatch.current = false;
	};

	const fetchCustomTemplates = async (nextPage: number) => {
		if (
			!campaignId ||
			loadedPages.current.has(nextPage) ||
			source === 'changeTemplates'
		) {
			return;
		}
		loadedPages.current.add(nextPage);
		const start = nextPage * TEMPLATES_PER_PAGE;
		const end = start + TEMPLATES_PER_PAGE;
		const newBatch = creatomatePlatformTemplates.slice(start, end);

		setCustomTemplates((prevCustomTemplates) => {
			const existingIds = new Set(
				prevCustomTemplates.map((template) => template?.templateId),
			);
			const newTemplatesToAdd = newBatch.filter(
				(template) => !existingIds.has(template?.templateId),
			);
			return [...prevCustomTemplates, ...newTemplatesToAdd];
		});

		setPage(nextPage);
		await processNewBatch(newBatch);
	};

	useEffect(() => {
		const fetchAllTemplates = async () => {
			if (hasSearchedRef.current) {
				return;
			}

			hasSearchedRef.current = true;

			const isCampaignContext =
				source === 'designDirections' || source === 'campaignForm';

			if (isCampaignContext) {
				if (lastSearchWithCampaign === false) {
					await fetchTemplatesByScope(campaign || undefined);
				} else if (!templatesLoaded) {
					await fetchTemplatesByScope(campaign || undefined);
				}
			} else {
				if (lastSearchWithCampaign === true) {
					await fetchTemplatesByScope();
				} else if (!templatesLoaded) {
					await fetchTemplatesByScope();
				}
			}
		};

		if (shouldFetchCustomTemplates) {
			if (
				creatomateAccountTemplates.length > 0 ||
				creatomatePlatformTemplates.length > 0
			) {
				if (creatomateAccountTemplates.length > 0) {
					fetchCustomAccountTemplates();
				}
				if (creatomatePlatformTemplates.length > 0) {
					fetchCustomTemplates(0);
				}
			}
			resetCampaignChangeStatus();
		}

		if (isOpen) {
			fetchAllTemplates();
		} else {
			hasSearchedRef.current = false;
		}
	}, [
		shouldFetchCustomTemplates,
		fetchTemplatesByScope,
		creatomateAccountTemplates,
		creatomatePlatformTemplates,
		campaign,
		source,
		lastSearchWithCampaign,
		isOpen,
	]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				handleModalClose();
			}
		};

		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen, modalRef]);

	useEffect(() => {
		if (!templatesLoaded || !industriesLoaded) return;
		if (shouldShowTopTemplates && templatesByScope.length > 0) {
			if (showCustomThumbnails) {
				const topTemplates = customTemplates
					.slice()
					.sort((a, b) => (b.score || 0) - (a.score || 0))
					.slice(0, 3)
					.map((template) => template?.templateId);
				setCurrentSelectedTemplatesIds(topTemplates);
				setTopTemplatesSelected(true);
				setHasAutoSelectedTopTemplates(true);
				return;
			}
			const topTemplates = templatesByScope
				.filter(
					(template) =>
						template.scope === 'platform' || template.scope === 'account',
				)
				.slice()
				.sort((a, b) => (b.score || 0) - (a.score || 0))
				.slice(0, 3)
				.map((template) => template?.id);
			setCurrentSelectedTemplatesIds(topTemplates);
			setTopTemplatesSelected(true);
			setHasAutoSelectedTopTemplates(true);
		} else {
			if (source === 'sidebar') {
				setCurrentSelectedTemplatesIds([]);
			} else if (!shouldShowTopTemplates && selectedTemplateIds.length === 0) {
				setCurrentSelectedTemplatesIds([]);
			}
		}
	}, [
		isOpen,
		shouldShowTopTemplates,
		templatesByScope,
		hasAutoSelectedTopTemplates,
		selectedTemplateIds,
		source,
		templatesLoaded,
		industriesLoaded,
	]);

	const handleHoverStart = useCallback((templateId: string) => {
		setHoveredTemplateId(templateId);
		hoverTimeoutRef.current = setTimeout(() => {
			setExtendedHoveredTemplateId(templateId);
		}, 1000);
	}, []);

	const handleHoverEnd = useCallback(() => {
		setHoveredTemplateId(null);
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
		setExtendedHoveredTemplateId(null);
	}, []);

	const handleModalClose = () => {
		if (
			source === 'gallery' ||
			source === 'sidebar' ||
			source === 'campaignForm' ||
			source === 'designDirections'
		) {
			setCurrentSelectedTemplatesIds([]);
			setSelectedTemplateIds([]);
		}
		cancelBatchProcessing.current = true;
		processingBatch.current = false;
		loadedPages.current.clear();
		setSelectedIndustries([]);
		setSelectedStyles([]);
		setSelectedSeasons([]);
		setHasSelectedFilters(false);
		setShowMyTemplates(false);
		onClose();
	};

	const handleGenerateDesign = async () => {
		setSelectedTemplateIds(currentSelectedTemplatesIds);
		if (source === 'gallery') {
			if (currentSelectedTemplatesIds.length > 0) {
				onClose();
				navigate('/projects/campaigns/new');
			}
			return;
		}

		if (source === 'sidebar') {
			navigate('/projects/campaigns/new');
			onClose();
			return;
		}

		if (source === 'changeTemplates') {
			onClose();
			return;
		}

		if (
			(source === 'designDirections' || source === 'campaignForm') &&
			currentSelectedTemplatesIds.length > 0 &&
			campaignId
		) {
			if (setIsGeneratingDD) {
				setIsGeneratingDD(true);
			}
			onClose();
			setScope('all');
			setCurrentSelectedTemplatesIds([]);
			try {
				const allCustomTemplates = [
					...customAccountTemplates,
					...customTemplates,
				];
				const selectedLayeredTemplates = allCustomTemplates.filter((template) =>
					currentSelectedTemplatesIds.includes(template?.templateId),
				);

				const templateParams = selectedLayeredTemplates.map((template) => {
					const realTemplateId = isCreatomateTemplate(template)
						? template.templateId
						: (template as ITemplate).id;
					return {
						adEngine: 'creatomate',
						outputs: {
							templateId: realTemplateId,
							score: template.score,
							width: template.width,
							height: template.height,
							fill_color: template.fill_color,
							output_format: template.output_format,
							fonts: template.fonts,
							elements: template.elements,
						},
					};
				});

				if (source === 'campaignForm' && onSubmit) {
					onSubmit(templateParams);
					return;
				}

				await generateDesignDirectionsFromLayers(campaignId, templateParams);
				if (handleRefreshDesignDirections) {
					await handleRefreshDesignDirections();
				}
				handleModalClose();
			} catch (error) {
				console.error('Error en generateDesignDirections:', error);
				toastError('Error generating design directions');
				if (setIsGeneratingDD) {
					setIsGeneratingDD(false);
				}
			}
			return;
		}
		if (currentSelectedTemplatesIds.length === 0 || !campaignId) {
			return;
		}
		if (setIsGeneratingDD) {
			setIsGeneratingDD(true);
		}
		onClose();
		setScope('all');
		setCurrentSelectedTemplatesIds([]);

		try {
			await generateDesignDirectionsWithTemplates(
				campaignId,
				currentSelectedTemplatesIds,
			);
			if (handleRefreshDesignDirections) {
				await handleRefreshDesignDirections();
			}
			handleModalClose();
		} catch {
			toastError('Error generating design directions');
			if (setIsGeneratingDD) {
				setIsGeneratingDD(false);
			}
		}
	};

	useEffect(() => {
		const anyFilterSelected =
			selectedIndustries.length > 0 ||
			selectedStyles.length > 0 ||
			selectedSeasons.length > 0;
		setHasSelectedFilters(anyFilterSelected);
	}, [selectedIndustries, selectedStyles, selectedSeasons]);

	useEffect(() => {
		if (currentSelectedTemplatesIds.length > 0 || hasAutoSelectedTopTemplates) {
			return;
		}
		if (isOpen && shouldShowTopTemplates && templatesByScope.length > 0) {
			if (showCustomThumbnails) {
				const topTemplates = customTemplates
					.slice(0, 3)
					.map((template) => template?.templateId);
				setCurrentSelectedTemplatesIds(topTemplates);
				setTopTemplatesSelected(true);
				setHasAutoSelectedTopTemplates(true);
			}
		}
	}, [
		isOpen,
		shouldShowTopTemplates,
		templatesByScope,
		hasAutoSelectedTopTemplates,
		customTemplates,
	]);

	const processingTemplatesRef = useRef<Set<string>>(new Set());
	useEffect(() => {
		if (
			isOpen &&
			source !== 'sidebar' &&
			source !== 'designDirections' &&
			selectedTemplateIds.length > 0
		) {
			const allTemplates = [
				...templates,
				...templatesByScope,
				...customTemplates,
				...customAccountTemplates,
			];

			const validTemplateIds = selectedTemplateIds.filter((id) =>
				allTemplates.some((t) =>
					isCreatomateTemplate(t) ? t.templateId === id : t.id === id,
				),
			);

			setCurrentSelectedTemplatesIds(validTemplateIds);

			if (showCustomThumbnails) {
				const processTemplates = async () => {
					const templatesToProcess = validTemplateIds.filter(
						(templateId) =>
							!processedCustomTemplates.some(
								(t) => t.templateId === templateId,
							) && !processingTemplatesRef.current.has(templateId),
					);

					if (templatesToProcess.length === 0) {
						return;
					}

					templatesToProcess.forEach((id) =>
						processingTemplatesRef.current.add(id),
					);

					await Promise.all(
						templatesToProcess.map(async (templateId) => {
							try {
								const template = [
									...customTemplates,
									...customAccountTemplates,
									...creatomatePlatformTemplates,
									...creatomateAccountTemplates,
								].find((t) => t?.templateId === templateId);

								if (!template) {
									processingTemplatesRef.current.delete(templateId);
									return;
								}

								const { callback } = await getTemplateLayers(
									templateId,
									campaignId!,
								);

								const creatomateAd = await processCallback(
									callback,
									CALLBACK_MAX_ATTEMPTS,
								);

								if (creatomateAd?.status === 'timeout') {
									setProcessedCustomTemplates((prev) => {
										const newState = [...prev, template];
										return newState;
									});
								}

								if (creatomateAd) {
									if (creatomateAd.status === 'successful') {
										await processCreatomateContent(creatomateAd);
									}
									if (creatomateAd.status === 'partialContent') {
										await pollProcessCallback(
											callback,
											processCreatomateContent,
										);
									}
								}
							} catch (error) {
								const template = [
									...customTemplates,
									...customAccountTemplates,
									...creatomatePlatformTemplates,
									...creatomateAccountTemplates,
								].find((t) => t?.templateId === templateId);

								if (template) {
									setProcessedCustomTemplates((prev) => {
										const newState = [...prev, template];

										return newState;
									});
								}
							} finally {
								processingTemplatesRef.current.delete(templateId);
							}
						}),
					);
				};

				processTemplates();
			}
		}
	}, [
		isOpen,
		source,
		selectedTemplateIds,
		templates.length,
		templatesByScope.length,
		showCustomThumbnails,
		campaignId,
	]);

	useEffect(() => {
		if (currentSelectedTemplatesIds.length < 3) {
			setTopTemplatesSelected(false);
		}
	}, [currentSelectedTemplatesIds]);

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef.current.slideTo(currentSelectedTemplatesIds.length - 1, 300);
		}
	}, [currentSelectedTemplatesIds]);

	const myAccountTemplatesExist = templates.some(
		(template) => template.scope === 'account',
	);

	useEffect(() => {
		const anyFilterSelected =
			selectedIndustries.length > 0 ||
			selectedStyles.length > 0 ||
			selectedSeasons.length > 0;
		setHasSelectedFilters(anyFilterSelected);
	}, [selectedIndustries, selectedStyles, selectedSeasons]);

	const getFilteredTemplates = (
		templatesList: ITemplate[],
		searchTerm: string,
		selectedIndustries: string[],
		selectedStyles: string[],
		selectedSeasons: string[],
	): any[] => {
		const term = searchTerm.trim().toLowerCase();

		return templatesList
			.filter((template) => {
				if (term) {
					const matchesId = template?.id.toLowerCase().includes(term);

					if (!matchesId) return false;
				}

				const matchesIndustry =
					selectedIndustries.length === 0 ||
					selectedIndustries.some(
						(ind) => template.attributes?.industries?.includes(ind),
					);
				const matchesStyle =
					selectedStyles.length === 0 ||
					(template.attributes?.style &&
						selectedStyles.includes(template.attributes.style));
				const matchesSeason =
					selectedSeasons.length === 0 ||
					(template.attributes?.season &&
						selectedSeasons.includes(template.attributes.season));

				return matchesIndustry && matchesStyle && matchesSeason;
			})
			.sort((a, b) => (b.score || 0) - (a.score || 0));
	};

	const filteredTemplates: FilteredTemplates = useMemo(() => {
		const unifiedTemplates = getFilteredTemplates(
			templatesByScope,
			searchTemplateId,
			selectedIndustries,
			selectedStyles,
			selectedSeasons,
		);

		const myTemplates = unifiedTemplates.filter(
			(template) => template.scope === 'account',
		);
		const allTemplates = unifiedTemplates.filter(
			(template) => template.scope === 'platform',
		);

		return {
			myTemplates: myTemplates.filter(
				(template) => !currentSelectedTemplatesIds.includes(template?.id),
			),
			allTemplates: allTemplates.filter(
				(template) => !currentSelectedTemplatesIds.includes(template?.id),
			),
			totalMyTemplates: myTemplates.length,
			totalAllTemplates: allTemplates.length,
		};
	}, [
		templatesByScope,
		searchTemplateId,
		selectedIndustries,
		selectedStyles,
		selectedSeasons,
		currentSelectedTemplatesIds,
		customTemplates,
		customAccountTemplates,
		processedCustomTemplates,
	]);

	useEffect(() => {
		if (!hasActiveFilter) return;
		const filteredTemplateIds = new Set(
			filteredTemplates.allTemplates.map((template) => template?.id),
		);
		const matchedTemplates = creatomatePlatformTemplates.filter((template) =>
			filteredTemplateIds.has(template?.templateId),
		);
		setFilteredCustomTemplates(matchedTemplates.slice(0, filteredDisplayLimit));
	}, [
		filteredTemplates,
		hasActiveFilter,
		filteredDisplayLimit,
		creatomatePlatformTemplates,
	]);

	const templatesToRender = hasActiveFilter
		? showCustomThumbnails
			? filteredCustomTemplates
			: filteredTemplates.allTemplates
		: showCustomThumbnails
		? customTemplates
		: filteredTemplates.allTemplates;

	useEffect(() => {
		if (
			templatesToRender.length > 0 &&
			templatesToRender.every((template) => isCreatomateTemplate(template))
		) {
			(templatesToRender as ICreatomateTemplate[]).forEach((template) => {
				if (template.elements) {
					const isProcessed = processedCustomTemplates.some(
						(processed) => processed.templateId === template.templateId,
					);
					if (isProcessed) {
						restoreOriginalContent(template.elements);
					} else {
						maskDefaultContent(template.elements);
					}
				}
			});
		}
	}, [templatesToRender, processedCustomTemplates]);

	const handleTemplateSelect = useCallback(
		(templateId: string) => {
			const template = templatesToRender.find((t) => {
				if (isCreatomateTemplate(t)) {
					return t.templateId === templateId;
				}
				return t.id === templateId;
			});

			setCurrentSelectedTemplatesIds((prev) => {
				let updated: string[] = [];
				if (prev.includes(templateId)) {
					updated = prev.filter((id) => id !== templateId);
				} else {
					updated = template ? [...prev, templateId] : prev;
				}

				const processedIds = processedCustomTemplates.map((p) => p.templateId);
				const allProcessed = updated.every((id) => processedIds.includes(id));

				return updated;
			});

			setHoveredTemplateId(null);
			if (hoverTimeoutRef.current) {
				clearTimeout(hoverTimeoutRef.current);
				hoverTimeoutRef.current = null;
			}
			setExtendedHoveredTemplateId(null);
		},
		[templatesToRender, currentSelectedTemplatesIds, processedCustomTemplates],
	);
	useEffect(() => {
		const bottom = bottomRef.current;
		const observer = new IntersectionObserver(
			(entries) => {
				const [entry] = entries;
				if (entry.isIntersecting) {
					if (hasActiveFilter) {
						const filteredTemplateIds = new Set(
							filteredTemplates.allTemplates.map((template) => template?.id),
						);
						const totalMatched = creatomatePlatformTemplates.filter(
							(template) => filteredTemplateIds.has(template?.templateId),
						).length;
						if (filteredDisplayLimit < totalMatched) {
							setFilteredDisplayLimit((prev) => prev + TEMPLATES_PER_PAGE);
						}
					} else {
						setDisplayLimit((prev) => prev + TEMPLATES_PER_PAGE);
						setIsLoadingMoreTemplates(true);
						fetchCustomTemplates(page + 1);
					}
				}
			},
			{ threshold: 1 },
		);

		if (bottom) {
			observer.observe(bottom);
		}

		return () => {
			if (bottom) {
				observer.unobserve(bottom);
			}
		};
	}, [
		processedCustomTemplates.length,
		filteredTemplates.allTemplates.length,
		page,
		fetchCustomTemplates,
	]);

	const stylesOptions = useMemo(() => {
		return styles
			.map((style) => ({ label: style, value: style }))
			.sort((a, b) => a.label.localeCompare(b.label));
	}, [styles]);

	const seasonsOptions = useMemo(() => {
		return seasons
			.map((season) => ({ label: season, value: season }))
			.sort((a, b) => a.label.localeCompare(b.label));
	}, [seasons]);

	const industriesOptions = useMemo(() => {
		const platform = templatesByScope.filter(
			(template) => template.scope === 'platform',
		);
		const templateIndustryIds = platform.flatMap(
			(template) => template.attributes?.industries || [],
		);
		const result = industries
			.filter((ind) => templateIndustryIds.includes(ind.industryIds[0]))
			.map((ind) => ({ label: ind.name, value: ind.industryIds[0] }))
			.sort((a, b) => a.label.localeCompare(b.label));
		return result;
	}, [industries, templatesByScope]);

	const handleResetAll = () => {
		setSelectedIndustries([]);
		setSelectedStyles([]);
		setSelectedSeasons([]);
		setHasSelectedFilters(false);
		setShowMyTemplates(false);
	};

	const handleSkipTemplate = useCallback((templateId: string) => {
		setCustomTemplates((prev) =>
			prev.filter((t) => t?.templateId !== templateId),
		);
		setCustomAccountTemplates((prev) =>
			prev.filter((t) => t?.templateId !== templateId),
		);
	}, []);

	const renderMyTemplatesGrid = useCallback(
		(templatesList: ICreatomateTemplate[] | ITemplate[]) => {
			const templatesToShow = templatesList.slice(0, displayLimit);
			return (
				<Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6} mt={4}>
					{templatesToShow.map((template, index) => {
						const templateId = isCreatomateTemplate(template)
							? template.templateId
							: template.id;
						const isHovered = hoveredTemplateId === templateId;
						const isExtendedHovered = extendedHoveredTemplateId === templateId;
						const columnIndex = index % columns;
						const rowIndex = Math.floor(index / columns);
						const previewTemplate = getTemplateForPreview(template);
						const transformOriginHorizontal =
							columnIndex === 0
								? 'left'
								: columnIndex === columns - 1
								? 'right'
								: 'center';
						const transformOriginVertical =
							rowIndex === 0 ? 'top' : rowIndex === 1 ? 'bottom' : 'center';
						const transformOrigin = `${transformOriginHorizontal} ${transformOriginVertical}`;
						const zIndex = isExtendedHovered || isHovered ? 2 : 1;
						const isPending = isCreatomateTemplate(template)
							? !processedCustomTemplates.some(
									(processed) => processed.templateId === templateId,
							  )
							: false;
						if (!template) return;

						return (
							<MotionBox
								key={templateId}
								aspectRatio={1}
								w="full"
								h="full"
								style={{ filter: 'grayscale(100%)' }}
								position="relative"
								borderWidth="3px"
								borderColor="transparent"
								borderRadius="md"
								overflow="hidden"
								boxShadow={isExtendedHovered ? 'xl' : 'md'}
								cursor="pointer"
								bg="white"
								initial={false}
								animate={{
									scale: isHovered ? 1.3 : 1,
									opacity: 1,
									zIndex,
								}}
								whileTap={{ scale: 0.9 }}
								transition={{
									type: 'spring',
									stiffness: 300,
									damping: 30,
									duration: 0.3,
								}}
								transformOrigin={transformOrigin}
								onMouseEnter={() => handleHoverStart(templateId)}
								onMouseLeave={handleHoverEnd}
								onClick={() => handleTemplateSelect(templateId)}
							>
								<Box key={templateId} aspectRatio={1}>
									<TemplatePreview
										ref={(el) => {
											previewRefs.current[templateId] = el;
										}}
										template={previewTemplate}
										onError={() => handleSkipTemplate(templateId)}
										showLoadingEffect={
											source === 'campaignForm' || source === 'designDirections'
										}
										showTemplateId={shouldShowTemplateIds()}
										isPending={isPending}
										showFallback={false}
									/>
								</Box>
							</MotionBox>
						);
					})}
				</Grid>
			);
		},
		[
			columns,
			currentSelectedTemplatesIds,
			pendingTemplates,
			hoveredTemplateId,
			extendedHoveredTemplateId,
			handleHoverStart,
			handleHoverEnd,
			handleTemplateSelect,
			displayLimit,
			showCustomThumbnails,
			handleSkipTemplate,
			source,
		],
	);

	const renderAllTemplatesGrid = useCallback(
		(templatesList: ICreatomateTemplate[] | ITemplate[]) => {
			const isCreatomate = isCreatomateTemplate(templatesList[0]);
			const availableTemplates = isCreatomate
				? (templatesList as ICreatomateTemplate[]).filter(
						(template) =>
							!currentSelectedTemplatesIds.includes(template?.templateId),
				  )
				: (templatesList as ITemplate[]).filter(
						(template) => !currentSelectedTemplatesIds.includes(template?.id),
				  );

			const templatesToShow = !showCustomThumbnails
				? availableTemplates
				: availableTemplates.slice(0, displayLimit);

			return (
				<Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6} mt={4}>
					{templatesToShow.map((template, index) => {
						const templateId = isCreatomateTemplate(template)
							? template.templateId
							: template.id;
						const isHovered = hoveredTemplateId === templateId;
						const isExtendedHovered = extendedHoveredTemplateId === templateId;
						const columnIndex = index % columns;
						const rowIndex = Math.floor(index / columns);
						const transformOriginHorizontal =
							columnIndex === 0
								? 'left'
								: columnIndex === columns - 1
								? 'right'
								: 'center';
						const transformOriginVertical =
							rowIndex === 0 ? 'top' : rowIndex === 1 ? 'bottom' : 'center';
						const transformOrigin = `${transformOriginHorizontal} ${transformOriginVertical}`;
						const zIndex = isExtendedHovered || isHovered ? 2 : 1;
						const isPending = isCreatomateTemplate(template)
							? !processedCustomTemplates.some(
									(processed) => processed.templateId === templateId,
							  )
							: false;

						const previewTemplate = getTemplateForPreview(template);

						if (!template) return;
						return (
							<MotionBox
								key={templateId}
								aspectRatio={1}
								w="full"
								h="full"
								position="relative"
								borderWidth="3px"
								borderColor="transparent"
								borderRadius="md"
								overflow="hidden"
								boxShadow={isExtendedHovered ? 'xl' : 'md'}
								cursor="pointer"
								bg="white"
								initial={false}
								animate={{
									scale: isHovered ? 1.3 : 1,
									opacity: 1,
									zIndex,
								}}
								whileTap={{ scale: 0.9 }}
								transition={{
									type: 'spring',
									stiffness: 300,
									damping: 30,
									duration: 0.3,
								}}
								transformOrigin={transformOrigin}
								onMouseEnter={() => handleHoverStart(templateId)}
								onMouseLeave={handleHoverEnd}
								onClick={() => handleTemplateSelect(templateId)}
							>
								<Box key={templateId} aspectRatio={1}>
									<TemplatePreview
										ref={(el) => {
											previewRefs.current[templateId] = el;
										}}
										template={previewTemplate}
										onError={() => handleSkipTemplate(templateId)}
										showLoadingEffect={
											source === 'campaignForm' || source === 'designDirections'
										}
										showTemplateId={shouldShowTemplateIds()}
										isPending={isPending}
										showFallback={false}
									/>
								</Box>
							</MotionBox>
						);
					})}
					<div
						ref={bottomRef}
						key={`bottom-ref-my-templates-${page}`}
						style={{ height: '1px' }}
					/>
				</Grid>
			);
		},
		[
			columns,
			currentSelectedTemplatesIds,
			pendingTemplates,
			hoveredTemplateId,
			extendedHoveredTemplateId,
			handleHoverStart,
			handleHoverEnd,
			handleTemplateSelect,
			displayLimit,
			showCustomThumbnails,
			page,
			handleSkipTemplate,
			source,
		],
	);

	const renderSelectedTemplates = () => {
		const handlePrevSlide = () => {
			if (swiperRef.current) {
				swiperRef.current.slidePrev();
			}
		};

		const handleNextSlide = () => {
			if (swiperRef.current) {
				swiperRef.current.slideNext();
			}
		};
		useEffect(() => {
			const selectedTemplates = currentSelectedTemplatesIds.map((id) =>
				templatesToRender.find((t) =>
					isCreatomateTemplate(t) ? t.templateId === id : t.id === id,
				),
			);
		}, [currentSelectedTemplatesIds, templatesToRender]);

		return (
			<Box
				onClick={(e) => {
					const target = e.target as HTMLElement;
					if (target.closest('.prev-slide, .next-slide')) return;
					if (!debounceRef.current) {
						debounceRef.current = true;
						setTimeout(() => {
							debounceRef.current = false;
						}, 600);
						setIsExpanded(!isExpanded);
					}
				}}
				cursor="pointer"
				borderRadius="md"
			>
				<Collapse in={!isExpanded} animateOpacity>
					<Box
						display="flex"
						mb={3}
						alignItems="center"
						justifyContent="flex-start"
						borderColor="#F7480B"
						borderRadius="md"
						p="8px"
						bg="#FFE6DD"
						transition="transform 0.3s ease-in-out"
					>
						<Flex alignItems="center">
							<Box
								width="20px"
								height="20px"
								fontSize="14px"
								borderRadius="50%"
								bg="#F7480B"
								color="white"
								display="flex"
								justifyContent="center"
								alignItems="center"
								fontWeight="bold"
								mr={2}
							>
								{currentSelectedTemplatesIds.length}
							</Box>
							<Text fontSize="14px" mr={2} color="black">
								{currentSelectedTemplatesIds.length === 1
									? 'Template selected'
									: 'Templates selected'}
							</Text>
						</Flex>
						<Flex>
							{currentSelectedTemplatesIds.slice(0, 3).map((templateId) => {
								const t = showCustomThumbnails
									? [
											...creatomatePlatformTemplates,
											...creatomateAccountTemplates,
									  ].find((x) => x?.templateId === templateId)
									: templates.find((x) => x?.id === templateId) ||
									  templatesByScope.find((x) => x?.id === templateId);

								const isPending = showCustomThumbnails
									? !processedCustomTemplates.some(
											(processed) => processed.templateId === templateId,
									  )
									: false;

								if (!t) return null;

								const previewTemplate = getTemplateForPreview(t);

								return (
									<Box
										key={templateId}
										width="30px"
										height="30px"
										borderRadius="md"
										overflow="hidden"
										mr={1}
									>
										<Box key={templateId} aspectRatio={1}>
											<TemplatePreview
												template={previewTemplate}
												onError={() => handleSkipTemplate(templateId)}
												showThumbnail={[
													'gallery',
													'sidebar',
													'changeTemplates',
												].includes(source || '')}
												showLoadingEffect={false}
												isPending={isPending}
											/>
										</Box>
									</Box>
								);
							})}
						</Flex>
						<Icon
							as={FiChevronDown}
							boxSize={6}
							color="#F7480B"
							style={{ marginLeft: 'auto' }}
						/>
					</Box>
				</Collapse>

				<Collapse in={isExpanded} animateOpacity>
					<Box position="relative" mb={3}>
						{currentSelectedTemplatesIds.length > 4 && (
							<Button
								className="prev-slide"
								position="absolute"
								top="50%"
								left="5"
								transform="translate(-50%, -50%)"
								zIndex="10"
								onClick={handlePrevSlide}
								bg={useColorModeValue('white', 'gray.700')}
								borderRadius="full"
								boxShadow="md"
								width="30px"
								height="30px"
								minWidth="auto"
								padding="0"
								_hover={{ bg: 'gray.100' }}
								style={{ display: isBeginning ? 'none' : 'block' }}
								disabled={isBeginning}
							>
								<ChevronLeftIcon
									w={6}
									h={6}
									color={isBeginning ? 'gray.400' : 'black'}
								/>
							</Button>
						)}

						<Swiper
							spaceBetween={20}
							slidesPerView="auto"
							pagination={false}
							onSwiper={(swiper: any) => {
								swiperRef.current = swiper;
								swiper.on('slideChange', () => {
									setIsBeginning(swiper.isBeginning);
									setIsEnd(swiper.isEnd);
								});
							}}
						>
							{currentSelectedTemplatesIds.map((templateId) => {
								const template = showCustomThumbnails
									? [
											...creatomatePlatformTemplates,
											...creatomateAccountTemplates,
									  ].find((x) => x?.templateId === templateId)
									: templates.find((x) => x?.id === templateId) ||
									  templatesByScope.find((x) => x?.id === templateId);

								const isPending = showCustomThumbnails
									? !processedCustomTemplates.some(
											(processed) => processed.templateId === templateId,
									  )
									: false;

								if (!template) return null;
								const previewTemplate = getTemplateForPreview(template);

								return (
									<SwiperSlide
										key={templateId}
										style={{
											width: '30vh',
											maxWidth: '330px',
											height: 'auto',
											maxHeight: '30vh',
										}}
									>
										<MotionBox
											className="template-container"
											position="relative"
											role="group"
											width="auto"
											height="auto"
											boxShadow="md"
											overflow="hidden"
											transition="transform 0.3s ease-in-out"
											initial={{ scale: 0.8, opacity: 0 }}
											animate={{ scale: 1, opacity: 1 }}
											exit={{ scale: 0.8, opacity: 0 }}
										>
											<Flex
												position="absolute"
												top="4px"
												right="4px"
												justifyContent="center"
												alignItems="center"
												width="20px"
												height="20px"
												zIndex="2"
												borderRadius="full"
												bg="white"
												boxShadow="sm"
												cursor="pointer"
												className="close-button"
												_hover={{ bg: 'gray.300', color: 'gray.700' }}
												onClick={(e) => {
													e.stopPropagation();
													handleTemplateSelect(templateId);
												}}
											>
												<CloseButton size="sm" />
											</Flex>

											<Box key={templateId} aspectRatio={1}>
												<TemplatePreview
													ref={(el) => {
														if (el) {
															previewRefs.current[templateId] = el;
														}
													}}
													template={previewTemplate}
													onError={() => handleSkipTemplate(templateId)}
													showLoadingEffect={
														source === 'campaignForm' ||
														source === 'designDirections'
													}
													showTemplateId={shouldShowTemplateIds()}
													isPending={isPending}
												/>
											</Box>
										</MotionBox>
									</SwiperSlide>
								);
							})}
						</Swiper>

						{currentSelectedTemplatesIds.length > 4 && (
							<Button
								className="next-slide"
								position="absolute"
								top="50%"
								right="5"
								transform="translate(50%, -50%)"
								zIndex="10"
								onClick={handleNextSlide}
								bg={useColorModeValue('white', 'gray.700')}
								borderRadius="full"
								boxShadow="md"
								width="30px"
								height="30px"
								minWidth="auto"
								padding="0"
								_hover={{ bg: 'gray.100' }}
								disabled={isEnd}
								style={{ display: isEnd ? 'none' : 'block' }}
							>
								<ChevronRightIcon
									w={6}
									h={6}
									color={isEnd ? 'gray.400' : 'black'}
								/>
							</Button>
						)}
					</Box>
				</Collapse>
			</Box>
		);
	};
	const hasTemplatesLoading = useMemo(() => {
		if (!showCustomThumbnails) {
			return false;
		}

		const processedIds = processedCustomTemplates.map((p) => p.templateId);

		const allSelectedProcessed = currentSelectedTemplatesIds.every((id) => {
			const isProcessed = processedIds.includes(id);
			return isProcessed;
		});

		return !allSelectedProcessed;
	}, [
		currentSelectedTemplatesIds,
		processedCustomTemplates,
		showCustomThumbnails,
	]);

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={handleModalClose}
			modalRef={modalRef}
			header={
				<Box>
					<HStack justifyContent="space-between" w="full" mb={2}>
						<Text fontWeight="bold" fontSize="16px" color="black">
							{header}
						</Text>
					</HStack>
					<Text fontSize="14px" pb={2} mt={1}>
						Choose the templates that you would like to use
					</Text>
					{renderSelectedTemplates()}
					<Box>
						<HStack spacing={4} align="start">
							{myAccountTemplatesExist && (
								<Button
									variant="outline"
									bg={showMyTemplates ? '#FFE8E0' : 'gray.50'}
									borderRadius="5px"
									px="12px"
									_focusVisible={{ outline: 'none' }}
									borderColor={showMyTemplates ? '#ffccbc' : 'gray.200'}
									_hover={
										showMyTemplates
											? {}
											: { bg: 'gray.200', borderColor: 'gray.300' }
									}
									_active={
										showMyTemplates ? { bg: '#FFE8E0' } : { bg: 'gray.200' }
									}
									onClick={() => setShowMyTemplates(!showMyTemplates)}
									fontFamily="Noto Sans"
									fontWeight="medium"
									height="10px"
									color={showMyTemplates ? '#F7480B' : 'inherit'}
									fontSize="14px"
								>
									<Flex alignItems="center">
										<Text
											mr={showMyTemplates ? 2 : 0}
											color={showMyTemplates ? '#F7480B' : 'inherit'}
											fontWeight="medium"
										>
											My Templates
										</Text>
										{showMyTemplates && <Icon as={FiX} boxSize={4} />}
									</Flex>
								</Button>
							)}
							{shouldShowTemplateIds() && (
								<>
									<InputGroup maxW="250px">
										<InputLeftElement pointerEvents="none">
											<SearchIcon color="gray.500" />
										</InputLeftElement>

										<Input
											placeholder="Search by template ID"
											value={searchInput}
											onChange={(e) => setSearchInput(e.target.value)}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													handleSearch();
												}
											}}
											borderRadius="5px"
											bg="gray.50"
											borderColor="gray.200"
											_hover={{ borderColor: 'gray.300' }}
											_focus={{ borderColor: '#F7480B', boxShadow: 'none' }}
											fontSize="14px"
										/>

										{searchInput && (
											<InputRightElement>
												<CloseButton
													size="sm"
													mr="2"
													onClick={() => {
														setSearchInput('');
														setSearchTemplateId('');
													}}
												/>
											</InputRightElement>
										)}
									</InputGroup>

									<Button
										ml={2}
										size="sm"
										colorScheme="orange"
										onClick={handleSearch}
									>
										Search
									</Button>
								</>
							)}

							<FilterDropdown
								label="Industry"
								options={industriesOptions}
								selectedOptions={selectedIndustries}
								onChange={setSelectedIndustries}
								loading={isFilterLoading}
							/>
							<FilterDropdown
								label="Style"
								options={stylesOptions}
								selectedOptions={selectedStyles}
								onChange={setSelectedStyles}
								loading={isFilterLoading}
							/>
							<FilterDropdown
								label="Seasonal"
								options={seasonsOptions}
								selectedOptions={selectedSeasons}
								onChange={setSelectedSeasons}
								loading={isFilterLoading}
							/>
							{hasSelectedFilters && (
								<Button
									onClick={handleResetAll}
									bg="gray.100"
									borderRadius="5px"
									color="black"
									_hover={{ bg: 'gray.200' }}
									fontFamily="Noto Sans"
									height="10px"
									fontWeight="medium"
									fontSize="14px"
								>
									Reset All
								</Button>
							)}
						</HStack>
					</Box>
				</Box>
			}
			footer={
				<Flex justify="flex-end" align="center" w="full">
					<Button
						isDisabled={
							currentSelectedTemplatesIds.length === 0 || hasTemplatesLoading
						}
						variant="orangeSolid"
						_hover={{ opacity: 0.9 }}
						_disabled={{ opacity: 0.7, cursor: 'not-allowed' }}
						onClick={handleGenerateDesign}
					>
						{source === 'sidebar' || source === 'gallery'
							? 'Generate ad'
							: 'Generate design'}
					</Button>
				</Flex>
			}
			size={size}
			isTemplateModal={true}
		>
			{isLoading ? (
				<FusionLoading isLoading={true} />
			) : (
				<Box>
					{showMyTemplates && (
						<>
							{filteredTemplates.myTemplates &&
							filteredTemplates.myTemplates.length > 0 ? (
								<>
									{!hasSelectedFilters && (
										<Text fontSize="14px" fontWeight="regular" mb={4}>
											My Templates
										</Text>
									)}
									{renderMyTemplatesGrid(
										hasActiveFilter
											? filteredTemplates.myTemplates
											: customAccountTemplates.length && showCustomThumbnails
											? customAccountTemplates
											: filteredTemplates.myTemplates,
									)}
								</>
							) : (
								<Box
									display="flex"
									minHeight="300px"
									flexGrow={1}
									alignItems="center"
									justifyContent="center"
									width="100%"
									height="100%"
									flexDirection="column"
								>
									<Flex alignItems="center">
										{filteredTemplates.totalMyTemplates > 0 ? (
											<Text
												textAlign="center"
												fontFamily="Noto Sans"
												fontSize="18px"
												fontWeight="bold"
											>
												No more results
											</Text>
										) : (
											<Text
												textAlign="center"
												fontFamily="Noto Sans"
												fontSize="18px"
												fontWeight="bold"
											>
												No templates found
											</Text>
										)}
										{hasSelectedFilters && (
											<Text
												as="button"
												color="blue.500"
												onClick={handleResetAll}
												textDecoration="underline"
												fontSize="14px"
												cursor="pointer"
												ml={2}
											>
												Remove filters
											</Text>
										)}
									</Flex>
								</Box>
							)}
						</>
					)}
					{!showMyTemplates && (
						<>
							{filteredTemplates.myTemplates &&
								filteredTemplates.myTemplates.length > 0 && (
									<>
										{!hasSelectedFilters && (
											<Text fontSize="14px" fontWeight="regular" mb={4}>
												My Templates
											</Text>
										)}
										{renderMyTemplatesGrid(
											customAccountTemplates.length && showCustomThumbnails
												? customAccountTemplates
												: filteredTemplates.myTemplates,
										)}
									</>
								)}
							{templatesToRender.length > 0 ? (
								<>
									{!hasSelectedFilters && (
										<Text fontSize="14px" fontWeight="regular" my={4}>
											All Templates
										</Text>
									)}
									{renderAllTemplatesGrid(templatesToRender)}
								</>
							) : (
								<Box
									display="flex"
									minHeight="300px"
									flexGrow={1}
									alignItems="center"
									justifyContent="center"
									width="100%"
									height="100%"
									flexDirection="column"
								>
									<Flex alignItems="center">
										{filteredTemplates.totalAllTemplates > 0 ? (
											<Text
												textAlign="center"
												fontFamily="Noto Sans"
												fontSize="18px"
												fontWeight="bold"
											>
												No more results
											</Text>
										) : (
											<Text
												textAlign="center"
												fontFamily="Noto Sans"
												fontSize="18px"
												fontWeight="bold"
											>
												No templates found
											</Text>
										)}
										{hasSelectedFilters && (
											<Text
												as="button"
												color="blue.500"
												onClick={handleResetAll}
												textDecoration="underline"
												fontSize="14px"
												cursor="pointer"
												ml={2}
											>
												Remove filters
											</Text>
										)}
									</Flex>
								</Box>
							)}
						</>
					)}
				</Box>
			)}
		</CustomModal>
	);
};

export default SelectTemplateModal;
